<template>
  <div class="dashboard-view">
    <div class="page-title">
      <h3>Главное</h3>
    </div>
    <div class="page-content">
      <div class="dashboard-datepicker mb-4">
        <p>Фильтр по дате</p>
        <div class="datepicker d-flex">
          <div class="datepicker-form__sh">
            <datepicker
                v-model="date"
                :input-class="'datepicker-input'"
                v-bind="{'disabled-dates': {from: new Date()}}"
                placeholder="Дата"
                format="yyyy-MM-dd"
                @input="filterDatepicker"
            ></datepicker>
            <span class="datepicker-img">
           <img src="../../../public/img/calendar.svg" alt="">
         </span>
          </div>
          <div class="d-flex day-btn">
            <button class="my-btn my-btn-day"
                    :class="type == 0 ? 'bg-blue' : '' "
                    @click="filterType(0)"
            >День</button>
            <button class="my-btn my-btn-day ml-20"
                    :class="type == 1 ? 'bg-blue' : '' "
                    @click="filterType(1)"

            >Месяц</button>
            <button class="my-btn my-btn-day ml-20"
                    :class="type == 2 ? 'bg-blue' : '' "
                    @click="filterType(2)"
            >Год</button>
          </div>
        </div>
      </div>
      <div class="table__sh-wrapper w-100 ml-3">
        <table class="table__sh w-100 border-0">
          <tr class="thead">
            <td style="width: 50px">№</td>
            <td>Наименование</td>
            <td>Uzcard</td>
            <td>Humo</td>
            <td>Сумма</td>
          </tr>
          <tr v-for="(item,index) in dashboards" :key="index">
            <td>{{index + 1}}</td>
            <td>{{item.userName}}</td>
            <td v-if="type==0">{{item.dailyUzcard  | financialFormatMoney}}</td>
            <td v-if="type==0">{{item.dailyHumo  | financialFormatMoney}}</td>
            <td v-if="type==0">{{item.daily  | financialFormatMoney}}</td>
            <td v-if="type==1">{{item.monthlyUzcard  | financialFormatMoney}}</td>
            <td v-if="type==1">{{item.monthlyHumo  | financialFormatMoney}}</td>
            <td v-if="type==1">{{item.monthly  | financialFormatMoney}}</td>
            <td v-if="type==2">{{item.yearlyUzcard  | financialFormatMoney}}</td>
            <td v-if="type==2">{{item.yearlyHumo  | financialFormatMoney}}</td>
            <td v-if="type==2">{{item.yearly  | financialFormatMoney}}</td>
          </tr>
        </table>
        <div class="chart">
          <vue-apex-charts width="100%" height="500px" :options="chartOptions" :series="series" :key="chartKey"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import VueApexCharts from 'vue-apexcharts'
export default {
  name: "Dashboard",
  components: {
    Datepicker,
    VueApexCharts
  },
  data() {
    return {
        dashboards:[],
        date: new Date(),
        type:0,
        dateUpdate:false,
      series: [
        {
          name: 'Uzcard',
          data:[]
        },
        {
          name: 'Humo',
          data: []
        }

      ],
      chartOptions: {
        colors: ['#173C7F', '#66B447'],
        xaxis: {
          categories: [],

        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return Intl.NumberFormat('ru-Ru').format(val) + " сум"
            }
          },
        },
        chart: {
					type: 'bar',
					stacked: true,
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: 30 + '%',

					},
        },
				dataLabels: {
					enabled: true,
					formatter: function (val) {
						return Intl.NumberFormat('ru-Ru').format(val) + " сум"
					},
				},

      },
      chartKey: 0,
    }
  },
  methods: {
    format(value){
      let val = (value/1).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    async getDashboards(){
      let params = {
        dateTime: this.date,
        cycleType: this.type
      }
      try {
        this.showLoader();
        const res = await this.$store.dispatch('getDashboards', params);
        this.dashboards = res.result;
        let name = [], uzcard = [], humo=[];
        this.dashboards.forEach(item => {

          if (item.daily){
            uzcard.push(item.dailyUzcard )
            humo.push(item.dailyHumo)
            name.push(item.userName + " : " + this.$options.filters.financialFormatMoney(item.daily) );
          }
          else if (item.monthly){
            uzcard.push(item.monthlyUzcard)
            humo.push(item.monthlyHumo)
            name.push(item.userName + " : " + this.$options.filters.financialFormatMoney(item.monthly) );

          }
          else  {
            uzcard.push(item.yearlyUzcard)
            humo.push(item.yearlyHumo)
            name.push(item.userName + " : " + this.$options.filters.financialFormatMoney(item.yearly) );
          }
        })
        this.chartOptions = {...this.chartOptions, ...{
            xaxis: {
              categories: name
            }
          }}
          this.series[0].data = uzcard
          this.series[1].data = humo
        this.chartKey++
      }
      catch (e){
        this.errorNotify(e.response.data.error.message);
      }
      finally {
        this.hideLoader();
      }
    },
    filterType(number){
      this.type = number;
      this.getDashboards();
    },
    filterDatepicker(){
      this.getDashboards();
    }
  },
  created() {
    this.getDashboards()
  },
  watch:{}
}
</script>
<style scoped>
.datepicker-form__sh{
  width: auto;
}
.day-btn .my-btn-day{
  margin-left: 20px;
}
.my-btn {
  outline: none;
  border-radius: 12px;
  border: none;
}
.my-btn-day {
  padding: 10px 30px;
  background-color: #EAF1FF;
  border: none;
}
.bg-blue{
  background-color: #005cbf;
  color: white;
}
.chart{
  margin-top: 30px;
  overflow: hidden;
  overflow-x: auto;
}

</style>
